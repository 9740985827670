/**
https://web.dev/articles/css-size-adjust#mitigating_cls_with_seamless_font_swapping
*/

/* This fixes issues seen in safari where fonts are automatically set to bold */
* {
  font-synthesis: none;
}

@font-face {
  src: local("Arial");
  font-family: "Inter-fallback";
  size-adjust: 107%;
  ascent-override: 90%;
}

@font-face {
  src: local("Arial");
  font-family: "Karla-fallback";
  size-adjust: 103.30000000000004%;
  ascent-override: 82%;
}

@font-face {
  font-family: "Karla";
  src:
    local("Karla"),
    url(./fonts/Karla.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url(./fonts/Inter.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoMono";
  src:
    local("RobotoMono"),
    url(./fonts/RobotoMono.ttf) format("truetype");
}
